.horizontalscroll{
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
}

.horizontalscroll .li-list{
    display: inline-block;
    list-style: none;
    width: 300px;
    min-width: 150px;
    padding: 5px;
}

.scrollContainer{
    display: flex;
    align-items: center;
}

